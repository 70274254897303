import React, { createElement, memo } from "react";
import { Center, Spinner, Text } from "@chakra-ui/react";

import Box from "../../components/Box";
import PieChartModule from "../Charts/PieChart";
import DebounceRenderChart from "../../components/DebounceRenderChart";

const RenderPieChart = memo(
  ({ pieData, total, layerToggle }) => {
    return (
      <Box px="1em" pt="1em">
        {pieData ? (
          pieData.some((d) => d?.length) ? (
            <DebounceRenderChart data={pieData}>
              {createElement(PieChartModule, {
                pieData,
                total,
                layerToggle,
              })}
            </DebounceRenderChart>
          ) : (
            <Center height={800}>
              <Text>未有資料</Text>
            </Center>
          )
        ) : (
          <Center height={800}>
            <Spinner />
          </Center>
        )}
      </Box>
    );
  },
  (prev, next) => JSON.stringify(prev.pieData) === JSON.stringify(next.pieData)
);

export default RenderPieChart;
