import React, { useMemo, useState, useEffect } from 'react'

// Demo styles, see 'Styles' section below for some notes on use.
import set from 'lodash/set'
import sortBy from 'lodash/sortBy'

import Box from '../../components/Box'

import useGetData from '../../contexts/useGetData'

import BudgetUseForAnalytics from './BudgetUseForAnalytics'
import Compare from './Compare'
import PieChartAnalytics from './PieChartAnalytics'

const Application = ({ filterCities, category, type, typeLabel, year, city, cities, setCity, finalType }) => {
  const [compare, setCompare] = useState(0)
  const [item, setItem] = useState()
  const [level, setLevel] = useState(0)
  const [recentYear, handleYear] = useState(+year)
  const { intactData: personnelData } = useGetData(cities, category, type, year, 'personnel')
  // const { intactData: now } = useGetData(cities, category, type, year, 'spendingDetails')
  const { intactData: upnow } = useGetData(cities, category, type, recentYear, 'spending-details')
  useEffect(() => {
  }, [compare])
  useEffect(() => {
    handleYear(+year)
  }, [year])
  const filterCityTotalBudget = useMemo(() => personnelData && city && personnelData?.filter(d => d?.city === city?.[1])?.[0]?.data.map(d => ({ ...d, total: d.value })), [city, personnelData])
  const usedOptions = useMemo(() => upnow && upnow.reduce((res, data) => {
    data.data.forEach(d => d.children.forEach(c => {
      if (!res.some(r => r.name === c.name)) {
        res.push({ id: c.id, name: c.name})
      }
    }))
    return res
  }, []), [upnow])
  const spendingDetail = useMemo(() =>  {
    if (!upnow) return []
    const nowData = upnow.map(d => item ? (
      { ...d, data: d.data.map(dd => ({ ...dd, children: dd.children.filter(c => c.name === item?.[0]) }))}
    ) : d).map((d) => ({
      name: d.city,
      type: d.type,
      year: d.year,
      id: d.id,
      ...d.data.reduce((res, r) => {
        r.children.forEach((c) => {
          if (r.name === '資本支出') {
            res.cp = res.cp + (finalType ? +c.final : +c.value)
          } else {
            res.ap = res.ap + (finalType ? +c.final : +c.value)
          }
        })
        return res
      }, { cp: 0, ap: 0 }) }))
    const resData = nowData.map(d => ({ ...d, rateAp: Math.round(d.ap * 1000 / (d.ap + d.cp)) / 10, rateCp: Math.round(d.cp * 1000 / (d.ap + d.cp)) / 10 }))
    return resData
  } ,[upnow, item, finalType])
  const pieChartDataInner = useMemo(() => {
    if (upnow) {
      const totals = upnow.filter(d => city ? d.city === city?.[1] : d).filter(d => d?.data.length).reduce((res, d) => {
        d?.data.forEach(c => {
          if (c.name === '資本支出') {
            if (res[0]) {
              res[0].value = +res[0].value + (finalType ? +c.final : +c.value)
            } else {
              res[0] = { name: '資本支出', value: (finalType ? +c.final : +c.value), fill: '#7879D8' }
            }
          } else {
            if (res[1]) {
              res[1].value = +res[1].value + (finalType ? +c.final : +c.value)
            } else {
              res[1] = { name: '經常支出', value: (finalType ? +c.final : +c.value), fill: '#22A2D3' }
            }
          }
        })
        return res
      }, [])

      return totals
    }
  }
  , [upnow, city, finalType])
  const pieChartSecData = useMemo(() => {
    if (upnow) {
      const filterData = [...upnow].filter(d => city ? d.city === city?.[1] : d)
      const totals = filterData.reduce((res, d) => {
        d?.data.forEach(c => c.children.forEach(r => {
          if (c.name === '資本支出') {
            const index = res[0].findIndex(dd => dd.name === r.name)
            if (index > -1) {
              res[0][index].value = +res[0][index].value + (finalType ? +r.final : +r.value)
              if (res[0][index].children && r.children) {
                r.children.forEach(cc => {
                  const key = res[0][index].children.findIndex(rr => rr.name === cc.name)
                  if (key > -1) {
                    res[0][index].children[key].value = +res[0][index].children[key].value + (finalType ? +cc.final : +cc.value)
                  } else {
                    const clonedCC = JSON.parse(JSON.stringify(cc))
                    res[0][index].children.push({ ...clonedCC, value: (finalType ? +clonedCC.final : +clonedCC.value) })
                  }
                })
              }
            } else {
              const clonedR = JSON.parse(JSON.stringify(r))
              res[0].push({ ...clonedR, value: (finalType ? +clonedR.final : +clonedR.value), fill: '#5B60BC', type: c.name })
            }
          } else {
            const index = res[1].findIndex(dd => dd.name === r.name)
            if (index > -1) {
              res[1][index].value = res[1][index].value + (finalType ? +r.final : +r.value)
              if (res[1][index].children && r.children) {
                r.children.forEach(cc => {
                  const key = res[1][index].children.findIndex(rr => rr.name === cc.name)
                  if (key > -1) {
                    res[1][index].children[key].value = +res[1][index].children[key].value +  (finalType ? +cc.final : +cc.value)
                  } else {
                    const clonedCC = JSON.parse(JSON.stringify(cc))
                    res[1][index].children.push({ ...clonedCC, value: (finalType ? +clonedCC.final : +clonedCC.value) })
                  }
                })
              }
            } else {
              const clonedR = JSON.parse(JSON.stringify(r))
              res[1].push({ ...clonedR, value: (finalType ? +clonedR.final : +clonedR.value), fill: '#068AB2', type: c.name })
            }
          }
        }))
        return res
      }, [[], []])
      const sortTotals = totals.map(arr => sortBy(arr, o => o.value))
      const result = sortTotals.flat()
      return result
    }
  }, [upnow, city, finalType])
  const pieThreeChartData = useMemo(() => {
    if (pieChartSecData) {
      return pieChartSecData.reduce((res, d) => {
        if (d.children) {
          const sortChildren = sortBy(d.children, o => o.value)
          if (d.type === '資本支出') {
            const addFillData = sortChildren.map(c => ({ ...c, fill: '#A2A6F4' }))
            res.push(...addFillData)
          } else {
            const addFillData = sortChildren.map(c => ({ ...c, fill: '#6ECFED' }))
            res.push(...addFillData)
          }
        } else {
          res.push({ ...d, fill: '#fff' })
        }
        return res
      }, [])
    }
  } ,[pieChartSecData])
  // const ready = useMemo(() => (upnow || personnelData) && filterCities?.length == personnelData?.length, [personnelData, filterCities])
  const sectionRef = React.useRef()
  // console.log(pieChartSecData)
  return (
    <Box bg="white" borderRadius={'0 0 2em 2em'} pt="2.75em" pb="4em" px="3.25em">
      <PieChartAnalytics
        setCity={setCity}
        city={city}
        category={category}
        typeLabel={typeLabel}
        setCompare={setCompare}
        compare={compare}
        setLevel={setLevel}
        level={level}
        data1={pieChartDataInner}
        data2={pieChartSecData}
        data3={pieThreeChartData}
      />
      <Compare
        cities={cities}
        ref={sectionRef}
        filterCities={filterCities}
        type={type}
        typeLabel={typeLabel}
        year={year}
        city={city}
        setCity={setCity}
        category={category}
        finalType={finalType}
      />
      <BudgetUseForAnalytics
        setCity={setCity}
        city={city}
        category={category}
        setItem={setItem}
        item={item}
        typeLabel={typeLabel}
        usedOptions={usedOptions}
        spendingDetail={spendingDetail}
      />
    </Box>
  )
}

export default Application
