import { createContext } from "react";

export const defaultLabels = {
  headTitle: '歲入歲出分析',
  updatedAt: '資料更新日期',
  budgetTitle: '預算數',
  revenueTitle: '歲入來源別科目分析',
  spendingTitle: '歲出來源別科目分析',
}

const labelContext = createContext(defaultLabels)

export default labelContext
