import React, { useRef } from "react";
import { AspectRatio, HStack, Text } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";

import Card from "../components/Card";
import UnitText from "../components/UnitText";
import DownloadButton from "./DownloadButton";

import TreeMap from "./Charts/TreeMap";
import useLabel from "../api/useLabel";

const SpendingTree = ({
  data,
  category,
  dataReady,
  typeLabel,
  city,
  finalType,
}) => {
  const label = useLabel("spendingTitle");
  const domRef = useRef();
  return (
    <Card
      title={label}
      hightlight={
        city
          ? city[1]
          : (category != "全部市縣" ? `全${category}` : null) ||
            `全${typeLabel}`
      }
      titleRight={
        <HStack spacing={2}>
          <UnitText />
          <DownloadButton title={label} domRef={domRef} />
        </HStack>
      }
      ref={domRef}
    >
      {dataReady ? (
        data?.length ? (
          <TreeMap
            finalType={finalType}
            data={data}
            colorScheme="blue"
            valueColor="darkBlue"
          />
        ) : (
          <AspectRatio ratio={1}>
            <Text>未有資料</Text>
          </AspectRatio>
        )
      ) : (
        <Spinner colorScheme="green" />
      )}
    </Card>
  );
};

export default SpendingTree;
