import React from 'react'
import useSWR from 'swr'

import labelContext, { defaultLabels } from './labelContext'

const LabelProvider = ({ children }) => {
  const { data = defaultLabels } = useSWR('/data/labels.json')
  return <labelContext.Provider value={data}>{children}</labelContext.Provider>
}

export default LabelProvider
