import round from "lodash/round";

const percent = (p, isString) => {
  const r = round((p || 0) * 100, 2);
  if (isString) {
    if (p > 0 && r < 0.01) return "< 0.01%";
    return `${r}%`;
  }
  return r;
};

export default percent;
