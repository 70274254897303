import React, { useMemo, useRef } from "react";
import { HStack, Flex, Select, Text } from "@chakra-ui/react";
import sumBy from "lodash/sumBy";
import sortBy from "lodash/sortBy";
import Box from "../../components/Box";
import Title from "../../components/Title";
import UnitText from "../../components/UnitText";
import DownloadButton from "../DownloadButton";
import RenderPieChart from "./RenderPieChart";

const useFor = ["一級用途別分析", "二級用途別分析"];
const options = ["經資分列", "經資併計"];
const fills = [
  "#6ECBC6",
  "#22A2D3",
  "#7879D8",
  "#F7C331",
  "#E27A0A",
  "#E0470B",
];
const lengeds = [
  { bg: "#22A2D3", label: "經常門" },
  { bg: "#7879D8", label: "資本門" },
];

const PieChartAnalytics = ({
  city,
  category,
  typeLabel,
  setCompare,
  compare,
  setLevel,
  year,
  level,
  data1,
  data2,
  data3,
}) => {
  const bottomRef = useRef();
  const pieData = useMemo(() => {
    if (data1 && data2 && data3) {
      const transformData2 = sortBy(
        data2.reduce((res, d) => {
          const index = res.findIndex((r) => r.name === d.name);
          if (index > -1) {
            res[index].value += d.value;
            if (d.children && !res[index].children) {
              res[index].children = d.children;
            }
          } else {
            const clonedObject = JSON.parse(JSON.stringify(d));
            res.push(clonedObject);
          }
          return res;
        }, []),
        (o) => +o.value
      );
      if (level) {
        if (compare) {
          return [
            transformData2.map((d, i) => ({ ...d, fill: fills[i] })),
            transformData2
              .map((d, i) => {
                if (d.children) {
                  const newC = sortBy(
                    d.children.map((dd) => ({ ...dd, fill: fills[i] })),
                    (o) => +o.value
                  );
                  if (sumBy(newC, (o) => o.value) < d.value) {
                    newC.push({
                      value: d.value - sumBy(d.children, (o) => o.value),
                      fill: "transparent",
                    });
                  }
                  return newC;
                } else {
                  return { ...d, fill: "transparent" };
                }
              })
              .flat(),
          ];
        } else {
          return [data1, data2, data3];
        }
      } else {
        if (compare) {
          return [transformData2.map((d, i) => ({ ...d, fill: fills[i] }))];
        } else {
          return [data1, data2];
        }
      }
    }
  }, [data1, data2, data3, level, compare]);
  const total = useMemo(
    () =>
      data1 &&
      data1.reduce((res, d) => {
        res = res + d.value;
        return res;
      }, 0),
    [data1]
  );
  // console.log(pieData)
  return (
    <Box pt="1em" pb="2.25em" ref={bottomRef}>
      <Title
        width="100%"
        city={city}
        category={category}
        typeLabel={typeLabel}
        title="歲出用途別分析"
        titleRight={
          <HStack>
            <Flex
              position="relative"
              rounded="full"
              bg="white"
              border={"1px solid white"}
              boxShadow={"2px 2px 2px rgba(0, 0, 0, 0.2)"}
              width="20em"
              fontSize="1.125em"
              mr="0.125em"
            >
              {options.map((label, i) => (
                <Box
                  width="100%"
                  key={i}
                  borderRadius="full"
                  px="1.25rem"
                  py="0.375rem"
                  color={compare === i ? "black" : "custom.gray"}
                  fontWeight={500}
                  position="relative"
                  transition="all 500ms"
                  letterSpacing="widest"
                  textAlign="center"
                  onClick={(e) => {
                    if (e && e.stopPropagation) {
                      e.stopPropagation();
                    }
                    setCompare(i);
                  }}
                  cursor="pointer"
                  overflow={"hidden"}
                  _after={{
                    content: '""',
                    position: "absolute",
                    display: "block",
                    border: "1px solid white",
                    left: "0",
                    right: "0",
                    top: "0",
                    bottom: "0",
                    filter: "drop-shadow(0px 0px 3px black)",
                    borderRadius: "full",
                    pointerEvents: "none",
                  }}
                >
                  {label}
                </Box>
              ))}
              <Box
                position="absolute"
                transition="all 500ms"
                bgGradient="linear(custom.orange, custom.yellow)"
                width="57%"
                height="130%"
                left="0"
                top="0"
                transform={`translate(${compare ? 78 : 0}%, -15%)`}
                rounded="full"
                boxShadow="md"
                overflow={"hidden"}
                _after={{
                  content: '""',
                  position: "absolute",
                  bgGradient: "linear(to-r, white, rgba(255, 255, 255, 0))",
                  display: "block",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  zIndex: 1,
                  pointerEvents: "none",
                  transform: "rotate(-30deg) translate(-45%, -125%)",
                }}
              >
                <Box.AbsCenter width="60%" textAlign="center">
                  {options[compare]}
                </Box.AbsCenter>
              </Box>
            </Flex>
            <Select
              value={level}
              flex={1}
              onChange={(e) => setLevel(+e.target.value)}
              onClick={(e) => {
                if (e && e.stopPropagation) e.stopPropagation();
              }}
            >
              {useFor.map((d, i) => (
                <option value={i} key={i}>
                  {d}
                </option>
              ))}
            </Select>
            <DownloadButton
              title="預算員額分析（經資併計）"
              domRef={bottomRef}
            />
          </HStack>
        }
      />
      <Flex mt="0.625em" gap={2} justifyContent="flex-end">
        {lengeds.map(({ bg, label }) => (
          <Flex alignItems={"center"} key={label}>
            <Box bg={bg} height="1em" width={"1.5em"} />
            <Text ml="0.5rem" color="#727171">
              {label}
            </Text>
          </Flex>
        ))}
        <UnitText fontSize="1em" />
      </Flex>
      <RenderPieChart
        key={JSON.stringify(pieData)}
        pieData={pieData}
        total={total}
      />
    </Box>
  );
};

export default PieChartAnalytics;
