import React from 'react'
import { SWRConfig } from 'swr'
import LabelProvider from './labelProvider'

// export const BASE_URL = `https://cbadcmst.dgbas.gov.tw/GTFL/api`
// export const BASE_URL = `/GTFL/api`
export const BASE_URL = process.env.PUBLIC_URL

const Provider = ({ children, options }) => (
  <SWRConfig
    value={{
      fetcher: (resource, init) => fetch(BASE_URL + resource, init).then(res => res.json()),
      ...options
    }}
  >
    <LabelProvider>
      {children}
    </LabelProvider>
  </SWRConfig>
)

export default Provider
