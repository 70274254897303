import { Select } from '@chakra-ui/react'
import React from 'react'

import Box from '../../components/Box'
import Arrow from '../../components/Arrow'

const OrgSelect = ({ options, onChange, org }) => {
  return (
    <Box border="1px solid white" borderRadius="2em" boxShadow={'2px 2px 2px rgba(0, 0, 0, 0.2)'} overflow="hidden">
      <Box.Relative className="select-wrapper">
        <Select value={org ? org[1] : 0} width="12.5em" border="none"
          onChange={(e) => {
            if(+e.target.value) {
              const orgName = options[+e.target.value].name
              onChange([orgName, +e.target.value])
            } else {
              onChange(null)
            }
          }}
          onClick={(e) => {
            if (e && e.stopPropagation) e.stopPropagation();
          }}
        >
          {options.map((d ,i) => (
            <option value={i} key={i}>{d.name.replace(/\|/, '')}</option>
          ))}
        </Select>
        <Arrow />
      </Box.Relative>
    </Box>
  )
}

export default OrgSelect
