import { Box, Text } from '@chakra-ui/react';
import { format } from 'd3-format';
import round from 'lodash/round';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  Rectangle,
  Cell,
  Tooltip,
} from 'recharts';

const fontSize = 16
const lineHeight = 20
const f = format(',d')
const minPointSize = 56
const legends = { ap: '經常門', cp: '資本門' }

const RenderCustomAxisTick = ({ x, y, payload, width, compare, onClick, index, city, org, left }) => {
  const w = fontSize * 4.5
  const h = fontSize * 2.125
  const active = compare ? (!org || !org?.[1] || (org?.[1] === index + 1)) : !city || city[2] === index
  const lines = React.useMemo(() => {
    const ls = payload.value.split('|')
    return (
      <g transform={`translate(0, -${(ls.length - 1) * lineHeight / 2})`}>
        {ls.map((t, i) => (
          <text
            key={i}
            x={w / 2}
            y={h / 2 + fontSize * 0.35 + i * lineHeight}
            fontSize={fontSize}
            textAnchor="start"
          >
            {t}
          </text>
        ))}
      </g>
    )
  }, [h, payload.value, w])
  return (
    <>
      <line x1={(left || (compare ? 230 : 10)) + width} x2={880} y1={y} y2={y} stroke="#ededed" />
      <g
        transform={`translate(${x - width - fontSize * (compare ? 16 : left ? 4 : 2)}, ${y - fontSize * 1.125})`}
        onClick={() => onClick(index)}
        style={{ cursor: 'pointer', opacity: active ? 1 : 0.33 }}
      >
        <Rectangle
          radius={8}
          width={w}
          height={h}
          fill="none"
          opacity="0.175"
        />
        {lines}
      </g>
    </>
  );
};

const CustomizedLabel = (props) => {
  const { x, y, height, width, value } = props;
  return !!value && width > minPointSize && (
    <g>
      <text
        x={x + width - 24}
        y={y + (height / 2) + 1}
        fill={"#fff"}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}%
      </text>
    </g>
  );
}

const CustomizedTotalList = (props) => {
  const { x, y, height, width, value, index, barWidth, data } = props;
  return !!value && (
    <g>
      <text
        // transform={!data[index].rateCp ? 'translate(-56, 0)'  : barWidth[index] && barWidth[index] < minPointSize && `translate(${minPointSize - barWidth[index]}, 0)`}
        x={x + width + ((value.toString().length + 1) * 5)}
        y={y + (height / 2) + 1}
        fill="#005678"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value.toLocaleString()}
      </text>
    </g>
  );
}

const CustomizedPayTotal = (props) => {
  const { y, height, value, dataKeyII } = props;
  return (
    <g>
      <text
        x={dataKeyII ? 1032 : 905}
        y={y + (height / 2) + 1}
        fill="#005678"
        textAnchor="end"
        dominantBaseline="middle"
      >
        {value.toLocaleString()}
      </text>
    </g>
  );
}

const CustomTooltip = ({ active, payload }) => {
  if (
    active &&
    payload &&
    payload.length &&
    payload[0]?.payload?.fill !== "transparent"
  ) {
    // console.log(p, payload)
    return payload.some(d => d.value > 0) && (
      <Box borderRadius={"1em"} bg="white" boxShadow={'1px 1px 3px'} px="0.75em" py="0.375em" overflow="hidden">
        <Text fontSize={"1.3125em"} color={"#585757"}>
          {payload[0].payload.name}
        </Text>
        {payload.map(
          (d) =>
            !!d.value && (
              <Box key={d.name}>
                <Text fontSize={"1.3125em"} color={d.color}>
                  <Text as="span" color="#585757" mr="0.5rem">{legends[d.name]}</Text>
                  {(d?.value * 100 / (d.payload.ap + d.payload.cp) < 0.01 || d?.value < 0) ? '< 0.01%' : `${round(d?.value * 100 / (d.payload.ap + d.payload.cp), 2)}%`}
                </Text>
              </Box>
            )
        )}
      </Box>
    );
  }

  return null;
};

const UseForBarChart = ({
  data,
  onSetCity,
  city,
  compare,
  right,
  left,
  setOrg,
  org,
  dataKey,
  dataKeyII,
  dataKeyIIColor,
  labelColor,
  width,
  labelKey,
  labelKeyII,
  labelPos,
}) => {
  const [barWidth, setWidth] = useState([])
  const handleLabelClick = useCallback((index) => {
    if (left) {
      if (org) {
        setOrg(null)
      } else {
        setOrg(data[index].name)
      }
    }
    if (compare) {
      const orgName = data[index].name
      setOrg(org?.[0] === orgName ? null : [orgName, index + 1])
    } else {
      const clicked = data[index].id
      onSetCity(city?.[0] === clicked ? null : clicked)
    }
  }, [data, onSetCity, city, org, setOrg, compare])
  // console.log(barWidth)
  return (
    <BarChart
      layout='vertical'
      width={width || 880}
      height={data?.length ? Math.max(55, data?.length * 40) : 1000}
      data={data}
      margin={{
        top: 0,
        right,
        left: left ||(compare ? 230 : 10),
        bottom: 0,
      }}
    >
      <XAxis
        type="number"
        tick={false}
        tickLine={false}
        axisLine={false}
        padding={{ right: 100 }}
      />
      <YAxis
        tickLine={false}
        axisLine={false}
        dataKey="name"
        type="category"
        tick={data.length && <RenderCustomAxisTick left={left} compare={compare} org={org} onClick={handleLabelClick} city={city} />}
      />
      <Tooltip cursor={false} content={<CustomTooltip />} />
      <Bar dataKey={dataKey || "total"} stackId="a" barSize={20} fill="#22A2D3" zIndex={3}>
        <LabelList
          dataKey={labelKey || dataKey || "total"}
          position={labelPos || "right"}
          fill={labelColor || "#5798C5"}
          content={labelKey && (
            <CustomizedLabel />
          )}
          formatter={f}
        />
        {data.map((entry, index) => (
          <Cell display={(entry[labelKey] || !labelKey)? 'block' : 'none'} opacity={(left ? (!org || org === data[index].name) : compare ? (!org?.[1] || (org?.[1] === index + 1) ? 1 : 0.33 ) : (!city || city[2] === index)) ? 1 : 0.33} key={`cell-${index}`} />
        ))}
      </Bar>
      {dataKeyII && (
        <Bar dataKey={dataKeyII} stackId="a" barSize={20} fill={dataKeyIIColor}>
          <LabelList
            dataKey={labelKeyII || dataKeyII}
            position={labelPos || "right"}
            fill={labelColor}
            content={(
              <CustomizedLabel
                isSecLine
                setWidth={setWidth}
                barWidth={barWidth}
                org={org}
              />
            )}
            formatter={f}
          />
          <LabelList
            // position={"right"}
            transform={'translate(10, 0)'}
            content={<CustomizedTotalList barWidth={barWidth} data={data} />}
            fill={"#5798C5"}
            formatter={f}
          />
          <LabelList
            dataKey={dataKey}
            // position={"right"}
            fill={"#5798C5"}
            content={<CustomizedPayTotal />}
            formatter={f}
          />
           <LabelList
            dataKey={dataKeyII}
            // position={"right"}
            fill={"#5798C5"}
            content={<CustomizedPayTotal dataKeyII={dataKeyII} />}
            formatter={f}
          />
          {data.map((entry, index) =>  (
            <Cell
              display={entry[labelKeyII] ? 'block' : 'none'}
              // transform={barWidth[index] < minPointSize && !!data[index][labelKey] ? `translate(${minPointSize - barWidth[index]}, 0)` : 'none'}
              opacity={(left ? (!org || org === data[index].name) : compare ? (!org?.[1] || (org?.[1] === index + 1) ? 1 : 0.33 ) : (!city || city[2] === index)) ? 1 : 0.33} key={`cell-${index}`} />
          ))}
        </Bar>
      )}
    </BarChart>
  )
}

export default UseForBarChart
