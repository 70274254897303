import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";

import ThemeProvider from "./components/ThemeProvider";
import MediaProvider from "./contexts/mediaQuery/MediaProvider";
import ExportConfigProvider from "./contexts/exportConfig/provider";

import ApiProvider from "./api/provider";

import App from "./App";
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <ThemeProvider>
    <MediaProvider>
      <ApiProvider>
        <ExportConfigProvider>
          <App />
        </ExportConfigProvider>
      </ApiProvider>
    </MediaProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
