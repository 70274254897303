import { HStack, Text } from '@chakra-ui/layout'
import React from 'react'
import useSWR from 'swr'
import useLabel from '../api/useLabel'

const UpdatedAt = () => {
  const { data } = useSWR('/data/lastUpdated.json')
  const label = useLabel('updatedAt')
  return (
    <HStack position={'absolute'} top="0" right="2em" transform={'translateY(-150%)'} color="#094063" fontSize="0.875em">
      <Text fontWeight="500">{label}:</Text>
      <Text fontWeight="500">{data?.date || ''}</Text>
    </HStack>
  )
}

export default UpdatedAt
