import { css } from '@emotion/react'

import bg from './bg@2x.png'

export default css`
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@600;700&family=Noto+Sans+TC:wght@500;700;900&display=swap');

  body {
    min-width: 100%;
    min-height: 100%;
  }

  img {
    width: 100%;
  }

  #root {
    background-image: url(${bg});
    background-size: 2480px;
    background-position: 50% 0;
  }

  #content {
    background-image: url(${bg});
    background-size: 2480px;
    background-position: 50% 0;
  }

  .select-wrapper {
    .chakra-select__wrapper::after {
      content: "";
      position: absolute;
      display: block;
      border: 1px solid white;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      filter: drop-shadow(0 0 3px black);
      pointer-events: none;
    }
    &:first-of-type .chakra-select__wrapper::after {
      border-top-left-radius: var(--chakra-radii-full);
      border-bottom-left-radius: var(--chakra-radii-full);
    }
    &:last-of-type .chakra-select__wrapper::after {
      border-top-right-radius: var(--chakra-radii-full);
      border-bottom-right-radius: var(--chakra-radii-full);
    }
  }
`;
