import React, { useRef } from 'react'
import {
  Center,
  HStack,
  Spinner,
} from '@chakra-ui/react'

import BarChart from './UseForBarChart'
import Box from '../../components/Box'
import Text from '../../components/Text'
import Flex from '../../components/Flex'
import Title from '../../components/Title'
import OrgSelect from './OrgSelect'
import DownloadButton from '../DownloadButton'
import UnitText from '../../components/UnitText'
import DebounceRenderChart from '../../components/DebounceRenderChart'

const legends = [
  { label: '經常門', bg: '#22A2D3', ap: '經常門' },
  { label: '資本門', bg: '#A2A6F4', cp: '資本門' },
]

const BudgetUseForAnalytics = ({
    city,
    category,
    typeLabel,
    setItem,
    setCity,
    item,
    usedOptions,
    spendingDetail,
  }) => {
  const bottomRef = useRef()
  // console.log(city)
  return (
    <Box pt="1em" pb="2.25em" ref={bottomRef}>
      <Title
        width="100%"
        city={city}
        category={category}
        typeLabel={typeLabel}
        title="歲出用途別分析"
        note={<UnitText ml="0.25rem" fontSize="0.875rem" letterSpacing="0em" />}
        titleRight={(
          <HStack>
            {usedOptions?.length && <OrgSelect options={[{ name: '全部' }, ...usedOptions]} onChange={setItem} org={item} />}
            <DownloadButton title="預算員額分析（經資併計）" domRef={bottomRef} />
          </HStack>
        )}
      />
      <Flex gap={2} pt="1.5em" pr="1.25em" justifyContent={'flex-end'}>
        {legends.map(({ label, bg }) => (
          <Flex gap={2} alignItems={'center'} key={label}>
            <Box bg={bg} width="3.75em" height={'1em'} />
            <Text>{label}</Text>
          </Flex>
        ))}
      </Flex>
      <Box px="1em" pt="1em">
        {spendingDetail.length ? (
          <DebounceRenderChart data={spendingDetail}>
            <BarChart
              width={1048}
              data={spendingDetail}
              city={city}
              onSetCity={setCity}
              setOrg={setItem}
              right={300}
              dataKey="ap"
              dataKeyII="cp"
              labelKey="rateAp"
              labelKeyII="rateCp"
              dataKeyIIColor="#A2A6F4"
              labelColor="#FFF"
            />
          </DebounceRenderChart>
        ) : <Center width={880} height={300}><Spinner /></Center>}
      </Box>
    </Box>
  )
}

export default BudgetUseForAnalytics
