import React, { useMemo } from "react";
import { Treemap, Tooltip } from "recharts";
import floor from "lodash/floor";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import { scaleLinear } from "d3-scale";
import { format } from "d3-format";
import { AspectRatio } from "@chakra-ui/react";

import Box from "../../components/Box";
import Text from "../../components/Text";
import percent from "../../utils/percent";

const f = format(",d");

const CustomTooltip = ({ active, payload, valueColor, ...props }) => {
  if (active && payload && payload.length) {
    const total = payload[0].payload.root.children.reduce((v, d) => {
      v = v + +d.value;
      return v;
    }, 0);
    return (
      <Box bg="rgba(255, 255, 255, 0.9)" borderRadius="0.5em" p="0.25em 0.5em">
        <Text fontSize="1.125em">{payload[0].payload.name}</Text>
        <Text fontSize="1.625em" color={`custom.${valueColor}`}>
          {`${f(payload[0].value)}`}
          <Text.Inline
            fontSize="1rem"
            ml="0.25rem"
            color="custom.darkGray"
          >{`(${percent(+payload[0].value / total, true)})`}</Text.Inline>
        </Text>
      </Box>
    );
  }
  return null;
};

const CustomizedContent = ({
  depth,
  x,
  y,
  width,
  height,
  name,
  colorScale,
  index,
}) => {
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: colorScale(index),
          stroke: "#fff",
          strokeWidth: 2,
        }}
      />
      {depth === 1 ? (
        <text
          x={x + width / 2}
          y={y + height / 2 + 7}
          textAnchor="middle"
          fill="#fff"
          fontSize={16}
        >
          {height < 16
            ? ""
            : width < name.length * 20
            ? `${name.substring(0, floor(width / 24))}...`
            : name}
        </text>
      ) : null}
    </g>
  );
};

const colors = {
  purple: ["#A2A6F4", "#7879D8"],
  blue: ["#6ECFED", "#068AB2"],
};

const TreeMap = ({ data, colorScheme, valueColor, finalType }) => {
  const treeData = useMemo(
    () =>
      data
        ? sortBy(
            map(data, ({ name, value, final }) => ({
              name,
              value: finalType ? +final : +value,
            })),
            ["value"]
          )
            .reverse()
            .filter((d) => d.value > 0)
        : [],
    [data, finalType]
  );
  // const extent = useMemo(() => {
  //   const min = minBy(treeData, 'value')
  //   const max = maxBy(treeData, 'value')
  //   return [min?.value || 0, max?.value || 1]
  // }, [treeData])
  const colorScale = useMemo(
    () => scaleLinear().domain([treeData.length, 0]).range(colors[colorScheme]),
    [treeData, colorScheme]
  );
  if (treeData.length === 0) {
    return (
      <AspectRatio ratio={1}>
        <Text>未有資料</Text>
      </AspectRatio>
    );
  }
  return (
    <Treemap
      width={550}
      height={600}
      data={treeData}
      ratio={4 / 3}
      dataKey="value"
      animationDuration={500}
      content={<CustomizedContent colorScale={colorScale} />}
    >
      <Tooltip valueColor={valueColor} content={<CustomTooltip />} />
    </Treemap>
  );
};

export default TreeMap;
