import domtoimage from 'dom-to-image';

const PIXEL_RATIO = (function () {
  if (typeof window === 'undefined') return 1
  const ctx = document.createElement('canvas').getContext('2d'),
      dpr = window.devicePixelRatio || 1,
      bsr = ctx.webkitBackingStorePixelRatio ||
            ctx.mozBackingStorePixelRatio ||
            ctx.msBackingStorePixelRatio ||
            ctx.oBackingStorePixelRatio ||
            ctx.backingStorePixelRatio || 1;
  return dpr / bsr;
})();

const setHiDPICanvas = function(canvas, w, h, ratio) {
  if (!ratio) { ratio = PIXEL_RATIO; }
  const can = canvas;
  can.width = w * ratio;
  can.height = h * ratio;
  can.style.width = w + 'px';
  can.style.height = h + 'px';
  can.getContext('2d').setTransform(ratio, 0, 0, ratio, 0, 0);
}

class Dom2Canvas {
  constructor(dom, w, h, style = '', styleFile) {
    this.dom = dom;
    this.canvas = null;
    this.ctx = null;
    this.width = w
    this.height = h;
    this.data = null;
    this.injectedStyle = style;
    if (styleFile) {
      fetch(styleFile)
        .then(res => res.text())
        .then((styleText) => {
          this.injectedStyle = styleText + this.injectedStyle

          this.loaded = true
        });
    } else {
      this.loaded = true
    }
    this.init();
  }

  init = () => {
    if (!this.width) this.width = this.dom.clientWidth;
    if (!this.height) this.height = this.dom.clientHeight;
    if (!this.loaded) {
      return setTimeout(this.init, 100)
    }

    this.canvas = document.createElement('canvas');
    setHiDPICanvas(this.canvas, this.width, this.height)
    this.ctx = this.canvas.getContext('2d');
    this._addBackgroundRect()
  };

  _addBackgroundRect = () => {
    this.ctx.fillStyle = '#ffffff'
    this.ctx.fillRect(0, 0, this.width, this.height)
  }

  _addNamespace = () => {
    this.dom.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');
  }

  toCanvas = (cb) => {
    if (this.loaded) {
      domtoimage.toPng(this.dom)
        .then((dataUrl) => {
          const image = new Image();
          image.src = dataUrl;
          image.onload = () => {
            this.ctx.drawImage(image, 0, 0)
            URL.revokeObjectURL(dataUrl);
            if (cb) {
              cb(this.canvas);
            }
          }
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });

    } else {
      setTimeout(() => this.toCanvas(cb), 100)
    }
  };
}

export default Dom2Canvas
