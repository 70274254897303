import { theme, extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools"
import memoizeOne from 'memoize-one';
import get from 'lodash/get';

const chakraBp = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "60em",
  xl: "80em",
  "2xl": "96em",
})

export const breakpoints = Object.values(chakraBp).slice(1)

export const responsiveIndex = [
  [1, 'mobile'],
  [2, 'tablet'],
  [3, 'laptop'],
  [4, 'desktop'],
]

const responsiveMap = breakpoints.map((_, i) => {
  const id = responsiveIndex.findIndex(([ri]) => ri > i)
  return id >= 0 ? id : responsiveIndex.length
})

export const responsive = memoizeOne((...args) => {
  const argsLen = args.length
  if (argsLen <= 1) return args[0]
  return breakpoints.map((_, i) => get(args, [responsiveMap[i]], null))
});

export const mobileOrDesktop = responsive

export const containerWidth = [null, "48em", "72em", "80em"];
export const containerPadding = '2em'

const font = 'Barlow, "Noto Sans TC", "PingFang TC", "HeiTi TC", "Microsoft JhengHei", sans-serif';
const fonts = {
  heading: font,
  body: font,
  mono: "Menlo, monospace",
}

const treeMapGreen = {
  100: '#A7E6E3',
  200: '#8FD9D5',
  300: '#7CCCC7',
  400: '#6FBFB9',
  500: '#62B3AE',
  600: '#53A6A2',
  700: '#449995',
  800: '#2F857E',
  900: '#2F857E',
  1000: '#2F857E',
}

const treeMapBlue = {
  100: '#CDE7FA',
  200: '#B2DAF7',
  300: '#92C9F0',
  400: '#76BCE8',
  500: '#5BA7D9',
  600: '#3A8EBF',
  700: '#2B77AD',
  800: '#0D5A8C',
}

const primary = 'blue'
const secondary = 'green'
const danger = 'red'

const overrides = {
  fonts,
  colors: {
    ...theme.colors,
    primary: get(theme.colors, `${primary}.500`),
    secondary: get(theme.colors, `${secondary}.500`),
    danger: get(theme.colors, `${danger}.500`),
    text: get(theme.colors, 'black'),
    custom: {
      gray: '#B1B1B2',
      darkBlue: '#068AB2',
      textOrange: '#E09200',
      textBlue: '#005678',
      strangeBlue: '#909b9f',
      lineChartBlue: '#00729f',
      orange: '#FFA323',
      yellow: '#FFD31F',
      orangeAlpha: 'RGBA(255, 163, 35, 0.5)',
      yellowAlpha: 'RGBA(255, 211, 31, 0.5)',
      lightCyan: '#8FD9D5',
      cyan: '#5EABA7',
      lightBlue: '#5798C5',
      labelBlue: '#00729F',
      blue: '#3380B5',
      darkGreen: '#2F857E',
      darkGray: '#898989',
      tabTextGray: '#b5b5b6',
      tabBgGray: '#dcdddd',
      unitGray: '#b4b4b5',
      legendText: '#727171',
      darkPurple: '#5B60BC',
      treeMapGreen,
      treeMapBlue,
    },
  },
  breakpoints,
  containerWidth,
  headerHeight: '5em',
}

const customTheme = extendTheme(overrides)

export default customTheme
