import useSWR from "swr";
import context from "./context";

const ExportConfigProvider = ({ children }) => {
  const { data: exportConfig, error } = useSWR(`/data/export.json`);
  if (!exportConfig && !error) return null;
  return (
    <context.Provider value={exportConfig ?? {}}>{children}</context.Provider>
  );
};

export default ExportConfigProvider;
