import React from 'react'
import { Heading, HStack } from '@chakra-ui/react'

import Box from './Box'
import BackgroundImage from './BackgroundImage'

import arrow from './arrow.svg'

const Title = ({ hightlight, title, noType, city, category, budgetType, typeLabel, text, note, titleRight, isExpanded, ...props }) => {
  return (
    <Box width={'100%'} {...props}>
      <HStack
        pb="0.875em"
        position="relative"
      >
        <Box transform={isExpanded && 'rotate(90deg)'}>
          <BackgroundImage width="1.375em" ratio={1} src={arrow} />
        </Box>
        <Heading color="#068AB2" fontSize="1.5em" letterSpacing="widest">
          {!noType && (city ? city[1] : (category != '全部市縣' ? `全${category}` : null) || `全${typeLabel}`)}
          {text}
          {typeof budgetType == "undefined" ? '' : budgetType ? '歲出' : '歲入'}
          {title}
          {note}
        </Heading>
        <Box flex="1" />
        {titleRight}
      </HStack>
      <Box height="1px" bg="linear-gradient(90deg, #238cc5, #b7a5d1)" />
    </Box>
  )
}

export default Title
