import { Center, Spinner } from "@chakra-ui/react";
import React, { cloneElement, useEffect, useState } from "react";

const DebounceRenderChart = ({
  data,
  timeout = 500,
  height = 300,
  children,
}) => {
  const [render, setRender] = useState(false);
  const [iter, setIter] = useState(0);

  useEffect(() => {
    if (data) {
      const cancel = setTimeout(() => {
        setRender(true);
        setIter(i => i + 1);
      }, timeout);
      return () => clearTimeout(cancel);
    }
  }, [JSON.stringify(data)]);
  // console.log(iter, render, data);
  return render ? (
    cloneElement(children, { key: iter })
  ) : (
    <Center height={height}>
      <Spinner />
    </Center>
  );
};

export default DebounceRenderChart;
