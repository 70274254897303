import React, { useCallback, useMemo } from "react";
import { BarChart, Bar, XAxis, YAxis, LabelList, Rectangle } from "recharts";
import { format } from "d3-format";
import { Spinner } from "@chakra-ui/spinner";
import { AspectRatio, HStack } from "@chakra-ui/react";

import useLabel from "../api/useLabel";
import Box from "../components/Box";
import Text from "../components/Text";
import Flex from "../components/Flex";
import Card from "../components/Card";
import theme from "../components/ThemeProvider/theme";
import UnitText from "../components/UnitText";

import DownloadButton from "./DownloadButton";

const legend = [
  {
    label: "歲入",
    bg: "#A2A6F4",
  },
  {
    label: "歲出",
    bg: "#22A2D3",
  },
];

const fontSize = 15;
const f = format(",d");

const RenderCustomAxisTick = ({
  x,
  y,
  payload,
  width,
  onClick,
  index,
  city,
}) => {
  const w = fontSize * 4.5;
  const h = fontSize * 2.125;
  const active = !city || city[2] === index;
  return (
    <g
      transform={`translate(${x - width - fontSize / 2}, ${
        y - fontSize * 1.125
      })`}
      onClick={() => onClick(index)}
      style={{ cursor: "pointer", opacity: active ? 1 : 0.33 }}
    >
      <Rectangle
        radius={8}
        width={w}
        height={h}
        fill="url(#Gradient1)"
        opacity="0.175"
      />
      <Rectangle
        radius={8}
        width={w}
        height={h}
        fill="none"
        stroke={theme.colors.custom.textBlue}
      />
      <text
        x={w / 2}
        y={h / 2 + fontSize * 0.35}
        fontSize={fontSize}
        fill={theme.colors.custom.textBlue}
        textAnchor="middle"
      >
        {payload.value}
      </text>
    </g>
  );
};

const BudgetBar = ({
  category,
  data,
  typeLabel,
  city,
  dataReady,
  onSetCity,
  finalType,
}) => {
  const chartRef = React.useRef();
  const label = useLabel(finalType ? "budgetTitleII" : "budgetTitle");
  const chartData = useMemo(
    () =>
      data
        ? data.map(({ name, revenue, spending }) => ({
            name,
            歲出: spending,
            歲入: revenue,
          }))
        : [],
    [data]
  );
  const handleLabelClick = useCallback(
    (index) => {
      const clicked = data[index].id;
      onSetCity(city?.[0] === clicked ? null : clicked);
    },
    [data, onSetCity, city]
  );
  // console.log(label)
  return (
    <Card
      ref={chartRef}
      title={`${(() => {
        if (data?.length === 1) return city[1];
        return `各${category !== "全部市縣" ? category : typeLabel}`;
      })()}${label}`}
      width="100%"
      titleRight={
        <HStack spacing={2}>
          {legend.map(({ label, bg }, i) => (
            <Flex alignItems="center" key={i}>
              <Box mr="0.25em" width="1.125em" py="0.375em" bg={bg} />
              <Text color="custom.labelBlue" fontSize={"0.875rem"}>
                {label}
              </Text>
            </Flex>
          ))}
          <UnitText />
          <DownloadButton
            title={`各${category ? category : typeLabel}${label}`}
            domRef={chartRef}
          />
        </HStack>
      }
    >
      {dataReady ? (
        chartData?.length ? (
          <BarChart
            layout="vertical"
            width={500}
            height={Math.max(65, data?.length * 55)}
            min={0}
            barGap={0}
            data={chartData}
            margin={{
              top: 0,
              right: 30,
              left: 20,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id="Gradient1" x1="0" x2="0" y1="0" y2="1">
                <stop offset="0%" stopColor="rgba(0,114,159,0)" />
                <stop offset="100%" stopColor="rgba(0,114,159,1)" />
              </linearGradient>
            </defs>
            <XAxis
              type="number"
              tick={false}
              tickLine={false}
              axisLine={false}
              strokeWidth={0}
              padding={{ right: 100 }}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              dataKey="name"
              type="category"
              tick={
                <RenderCustomAxisTick onClick={handleLabelClick} city={city} />
              }
            />
            <Bar dataKey="歲入" barSize={15} fill={"#A2A6F4"}>
              <LabelList
                dataKey="歲入"
                position="right"
                fill={"#7879D8"}
                formatter={f}
              />
            </Bar>
            <Bar dataKey="歲出" barSize={15} fill={"#22A2D3"}>
              <LabelList
                dataKey="歲出"
                position="right"
                fill={"#068AB2"}
                formatter={f}
              />
            </Bar>
          </BarChart>
        ) : (
          <AspectRatio ratio={1}>
            <Text textAlign="center">未有資料</Text>
          </AspectRatio>
        )
      ) : (
        <Spinner colorScheme="green" />
      )}
    </Card>
  );
};

export default BudgetBar;
