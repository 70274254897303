import React from 'react'

import Box from '../../components/Box'

import Compare from './Compare'
import PieAnalytics from './PieAnalytics'

const Analytics = ({
  cities,
  budgetType,
  isSub,
  category,
  filterCities,
  type,
  typeLabel,
  year,
  city,
  revenue,
  spending,
  revenueTotal,
  spendingTotal,
  setCity,
  finalType
}) => {
  const sectionRef = React.useRef()
  return (
    <Box py="2.25em" bg="white" borderRadius={'0 0 2em 2em'}>
      <PieAnalytics
        cities={cities}
        type={type}
        year={year}
        city={city}
        filterCities={filterCities}
        category={category}
        typeLabel={typeLabel}
        revenue={revenue}
        spending={spending}
        budgetType={budgetType}
        finalType={finalType}
      />
      <Compare
        cities={cities}
        ref={sectionRef}
        finalType={finalType}
        filterCities={filterCities}
        type={type}
        typeLabel={typeLabel}
        year={year}
        city={city}
        revenue={revenue}
        spending={spending}
        revenueTotal={revenueTotal}
        spendingTotal={spendingTotal}
        setCity={setCity}
        category={category}
        isSub={isSub}
        budgetType={budgetType}
      />
    </Box>
  )
}

export default Analytics
