import { IconButton, Tooltip } from '@chakra-ui/react'
import React, { useRef } from 'react'
import saveAs from 'file-saver'
import { RiDownloadLine } from 'react-icons/ri'

import Dom2Canvas from '../utils/dom2canvas'
import '../utils/Blob'
import '../utils/canvas-toBlob'

const findButton = (master, self) => {
  let found
  const dig = (parent) => {
    if (parent.hasChildNodes()) {
      for (let index = 0; index < parent.childNodes.length; index++) {
        const element = parent.childNodes[index];
        if (element.className === self.className) {
          // element.remove()
          found = element
        }
        if (found) return
        dig(element)
      }
    }
  }
  dig(master)
  return [master, found]
}

const DownloadButton = ({ domRef, title = '匯出' }) => {
  const selfRef = useRef()
  const [isLoading, setIsLoading] = React.useState()
  return (
    <Tooltip label="下載圖表">
      <IconButton
        ref={selfRef}
        size="sm"
        icon={<RiDownloadLine size="1.5em" />}
        isLoading={isLoading}
        colorScheme="yellow"
        bgGradient="linear(to-r, #FF8C23, #FFC21F)"
        color="white"
        _hover={{
          bgGradient: "linear(to-r, #FF8C23, #FFC21F)",
          color: "white",
        }}
        onClick={(e) => {
          setIsLoading(true)
          if (e && e.stopPropagation) e.stopPropagation();
          const { width, height } = domRef.current.getBoundingClientRect()
          const [cleaned, found] = findButton(domRef.current, selfRef.current)
          // cleaned.style.width = width
          // cleaned.style.height = height
          // document.getElementById('root').append(cleaned)
          found.style.visibility = 'hidden'
          cleaned.style.whiteSpace ='nowrap'
          const ins = new Dom2Canvas(cleaned, width, height)
          ins.toCanvas((canvas) => {
            // presentational style
            canvas.style.width = '100%'
            canvas.style.height = 'auto'
            new Promise((res) => {
              const date = new Date().toLocaleDateString()
              canvas.toBlob((blob) => {
                saveAs(blob, `${date}-${title}.png`);
                found.style.visibility = 'visible'
                cleaned.style.whiteSpace = 'auto'
                res(setIsLoading(false))
              });
            })
          })
        }}
      />
    </Tooltip>
  )
}

export default DownloadButton
