import React from 'react'
import arrow from './arrow.png'
import Box from '../Box'
import BackgroundImage from '../BackgroundImage'

const Arrow = () => {
  return (
    <Box.Absolute pointerEvents="none" top="0" bottom="0" right="0" width="2.5em">
      <BackgroundImage src={arrow} ratio={1} />
    </Box.Absolute>
  )
}

export default Arrow
