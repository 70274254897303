import React, { useMemo, useState } from "react";
import { Box, HStack, forwardRef } from "@chakra-ui/react";
import sumBy from "lodash/sumBy";

import Title from "../../components/Title";
import UnitText from "../../components/UnitText";
import useGetData from "../../contexts/useGetData";

import DownloadButton from "../DownloadButton";
import RenderPieChart from "../Application/RenderPieChart";

const cloneJSON = (json) => JSON.parse(JSON.stringify(json));

const handleArraySum = (res, data) => {
  if (res[data.id]) {
    res[data.id].value += data.value;
  } else {
    res[data.id] = cloneJSON(data);
  }
  if (data.children) {
    res[data.id].parsedChildren = data.children.reduce(
      handleArraySum,
      res[data.id].parsedChildren ?? {}
    );
  }
  return res;
};

const flatChildren = (obj) =>
  Object.values(obj)
    .map(({ parsedChildren, ...d }) =>
      parsedChildren
        ? {
            ...d,
            children: flatChildren(parsedChildren),
          }
        : d
    )
    .sort((a, b) => b.value - a.value);

const layerColors = ["#7895d8", "#7879D8", "#788bd8"];

const PieAnalytics = forwardRef(
  ({ cities, city, category, type, typeLabel, budgetType, year }) => {
    const { intactData: data } = useGetData(
      cities,
      category,
      type,
      year,
      budgetType ? "spending" : "revenue"
    );
    const domRef = React.useRef();
    const formattedData = React.useMemo(() => {
      if (data) {
        if (city) {
          return data[city[2]]?.data;
        }
        const reduced = data.reduce(
          (res, cityData) => cityData.data.reduce(handleArraySum, res),
          {}
        );
        return flatChildren(reduced).sort((a, b) => b.value - a.value);
      }
    }, [city, data]);

    const total = React.useMemo(
      () => sumBy(formattedData, (d) => +d.value),
      [formattedData]
    );

    const pieData = useMemo(() => {
      return formattedData?.reduce((all, cell) => {
        const handleData = ({ children, ...data }, layer = 0) => {
          all[layer] = all[layer] || [];
          const newLength = all[layer].push({
            ...data,
            fill: layerColors[layer],
            index: all[layer].length,
          });
          if (children) {
            children.forEach((child) =>
              handleData(
                {
                  ...child,
                  parent: {
                    index: newLength - 1,
                    parent: data.parent,
                  },
                },
                layer + 1
              )
            );
          }
        };
        handleData(cell);
        return all;
      }, []);
    }, [formattedData]);

    return (
      <Box px="1em" pt="1em" ref={domRef}>
        <Title
          category={category}
          city={city}
          typeLabel={typeLabel}
          budgetType={budgetType}
          px="1em"
          text="總預算－"
          title={budgetType ? "政事別" : "來源別"}
          note={<UnitText ml="0.5em" fontSize="0.875rem" letterSpacing="0" />}
          titleRight={<DownloadButton title="總預算" domRef={domRef} />}
        />

        <RenderPieChart
          key={JSON.stringify(pieData)}
          pieData={pieData}
          total={total}
          layerToggle
        />
      </Box>
    );
  }
);

export default PieAnalytics;
