import { Box, Heading, HStack, Text } from '@chakra-ui/layout'
import React from 'react'
import { forwardRef } from '@chakra-ui/react'

import BackgroundImage from './BackgroundImage'
import UnitText from './UnitText'

import arrow from './arrow.svg'

const Card = forwardRef(({
  title,
  hightlight,
  titleRight,
  children,
  ...props
}, ref) => (
  <Box
    // rounded="2em"
    py="1.625em"
    px="1.25em"
    ref={ref}
    {...props}
  >
    <HStack
      pb="1em"
      mb="1.5em"
      position="relative"
      spacing="0.625em"
      _before={{
        content: '""',
        display: 'block',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: '1px',
        bgImage: 'linear-gradient(to right, #238CC5, #B7A5D1)',
      }}
    >
      <BackgroundImage width="1.375em" ratio={1} src={arrow} />
      <Heading style={{ marginLeft: '0.625rem' }} color="custom.textBlue" fontSize="1.5em" letterSpacing="widest">
        {hightlight && <Text as="span">{hightlight}</Text>}
        {title}
      </Heading>
      <Box flex="1" />
      {titleRight || (
        <UnitText />
      )}
    </HStack>
    {children}
  </Box>
))

export default Card
