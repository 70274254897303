import { Center, forwardRef, Spinner, HStack, Text } from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
// import set from 'lodash/set'
import includes from "lodash/includes";
import last from "lodash/last";
// import compact from 'lodash/compact'
// import findIndex from 'lodash/findIndex'
// import first from 'lodash/first'
import range from "lodash/range";

import Box from "../../components/Box";
import Flex from "../../components/Flex";
import Title from "../../components/Title";
import useGetData from "../../contexts/useGetData";
import LineChartModule from "../Charts/LineChart";
import DownloadButton from "../DownloadButton";
import useYears from "../useYears";
import UnitText from "../../components/UnitText";
import DebounceRenderChart from "../../components/DebounceRenderChart";
import getChineseNumber from "../../utils/getChineseNumber";

const legends = [
  { bg: "#068AB2", label: "經常門" },
  { bg: "#7879D8", label: "資本門" },
];

const RenderLineChart = ({ data, yearLength }) => {
  return (
    <DebounceRenderChart data={data}>
      <LineChartModule
        left={15}
        right={30}
        width={504}
        data={data.slice(-yearLength)}
        dataKey="cp"
        dataKeyII="ap"
      />
    </DebounceRenderChart>
  );
};

const CompareCharts = ({
  ready,
  city,
  budgetData,
  year,
  finalType,
  yearLength,
  // yearIndex,
}) => {
  const data = useMemo(
    () =>
      city && budgetData
        ? budgetData?.map((d) => d?.filter((dd) => dd?.city === city?.[1]))
        : budgetData,
    [budgetData, city]
  );
  // console.log(data)
  const detailYearDatas = useMemo(() => {
    if (!data) return [];
    const detailData = data.reduce((res, d, k) => {
      d?.forEach((v) => {
        if (v.data?.length) {
          v?.data.forEach((da) => {
            da.children.forEach((c) => {
              const isAp = da.name === "經常支出";
              const key = isAp ? "ap" : "cp";
              const finalValue = finalType ? +c.final : +c.value;

              if (res.some((r) => r.some((rr) => rr.name === c.name))) {
                const index = res.findIndex((r) =>
                  r.some((rr) => rr.name === c.name)
                );
                if (res[index][k]) {
                  res[index][k][key] = (res[index][k][key] || 0) + finalValue;
                } else {
                  res[index].push({
                    ...c,
                    year: v.year - 1911,
                    [key]: finalValue,
                  });
                }
              } else {
                // init year
                const array = range(k + 1).map((d, index, { length }) => {
                  if (index === length - 1) {
                    return {
                      ...c,
                      year: v.year - 1911,
                      [key]: finalValue,
                    };
                  } else {
                    return {
                      ...c,
                      year: v.year - 1911 - (length - 1 - d),
                      [key]: finalValue,
                    };
                  }
                });
                res.push(array);
              }
            });
          });
        } else {
          res.forEach((r) => {
            const year = v.year - 1911;
            const hasYear = r.some((rr) => rr.year === year);
            if (!hasYear) {
              r.push({
                id: r[0].id,
                name: r[0].name,
                year,
              });
            }
          });
        }
      });
      return res;
    }, []);
    return detailData.map((d) =>
      d.map((dd) => ({
        ...dd,
        ap: dd.ap || 0,
        cp: dd.cp || 0,
      }))
    );
  }, [data, finalType]);
  // console.log(detailYearDatas);
  return (
    <Flex flexWrap={"wrap"} pt="2.5em">
      {detailYearDatas.length ? (
        detailYearDatas?.map((d, i) => (
          <Box width={"50%"} key={i}>
            {ready ? (
              <RenderLineChart
                key={JSON.stringify(d) + year}
                data={d}
                yearLength={yearLength}
              />
            ) : (
              <Center width={370} height={198}>
                <Spinner />
              </Center>
            )}
          </Box>
        ))
      ) : (
        <Center width="100%" height={198}>
          未有資料
        </Center>
      )}
    </Flex>
  );
};

const transformData = (data, years, year) =>
  data?.map((d, i, { length }) => {
    const theYear = year - (length - 1 - i);
    if (years?.includes(theYear) && d) return d;
    return last(data)?.map((d) => ({ ...d, year: theYear, data: [] }));
  });

const Compare = forwardRef(
  ({
    cities,
    budgetType,
    category,
    type,
    typeLabel,
    year,
    city,
    finalType,
  }) => {
    const years = useYears();
    const dataType = "spending-details";
    const { intactData: ed } = useGetData(
      cities,
      category,
      type,
      year - 1,
      dataType
    );
    const { intactData: eded } = useGetData(
      cities,
      category,
      type,
      year - 2,
      dataType
    );
    const { intactData: ededed } = useGetData(
      cities,
      category,
      type,
      year - 3,
      dataType
    );
    const { intactData: edededed } = useGetData(
      cities,
      category,
      type,
      year - 4,
      dataType
    );
    const { intactData: now } = useGetData(
      cities,
      category,
      type,
      year,
      dataType
    );
    const all = useMemo(
      () =>
        (edededed || ededed || eded || ed || now) &&
        transformData([edededed, ededed, eded, ed, now], years, year),
      [years, year, edededed, ededed, eded, ed, now]
    );
    const readytArr = useMemo(
      () =>
        [edededed, ededed, eded, ed, now].map((d, i, { length }) => {
          if (includes(years, year - (length - 1 - i))) return d;
          else return null;
        }),
      [edededed, ededed, eded, ed, now, years, year]
    );
    const ready = useMemo(
      () =>
        readytArr?.every((d, i) => {
          if (includes(years, year - (readytArr.length - 1 - i))) return d;
          else return !d;
        }),
      [years, year, readytArr]
    );
    // const [ready, setReady] = useState()
    // useEffect(() => {
    //   setReady(false)
    //   setTimeout(() => {
    //     setReady(true)
    //   }, 1000)
    // } ,[year])
    const chartRef = useRef();
    // console.log(ready)
    return (
      <Box pt="1em" pb="2.25em" ref={chartRef}>
        <Title
          city={city}
          category={category}
          typeLabel={typeLabel}
          title={`歲出用途別近${getChineseNumber(years.length)}年比較`}
          titleRight={
            <HStack>
              {legends.map(({ bg, label }) => (
                <Flex alignItems={"center"} key={label}>
                  <Box bg={bg} height="1em" width={"1.5em"} />
                  <Text ml="0.5rem" color="#727171">
                    {label}
                  </Text>
                </Flex>
              ))}
              <UnitText ml="0.25rem" fontSize="0.875rem" letterSpacing="0em" />
              <DownloadButton
                domRef={chartRef}
                title={`歲出用途別近${getChineseNumber(years.length)}年比較`}
              />
            </HStack>
          }
        />
        <CompareCharts
          {...{
            city,
            budgetData: all || [],
            budgetType,
            ready,
            year,
            finalType,
            yearLength: years.length,
          }}
        />
      </Box>
    );
  }
);

export default Compare;
