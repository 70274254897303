import { useContext } from 'react'

import labelContext from './labelContext'

const useLabel = (key) => {
  const labels = useContext(labelContext)
  return key ? labels[key] : labels
}

export default useLabel
