import findIndex from 'lodash/findIndex'
import set from 'lodash/set'
import { useMemo } from 'react'
import useSWRInfinite from 'swr/infinite'
import useYears from '../containers/useYears'

const numberfy = (json) => JSON.parse(JSON.stringify(json).replace(/("(?!id)\w+":)"(\d+)"/g, '$1$2'))

const useGetData = (cities, category, type, year, datakey) => {
  const years = useYears()

  const getKey = (pageIndex, previousPageData) => {
    if (!cities?.[pageIndex] || years.every(y => y != year)) return null // no data
    return `/data/${type}/${year}/${cities?.[pageIndex].id}/${datakey}.json`
  }

  const { data } = useSWRInfinite(getKey, { initialSize: cities?.length })

  const intactData = useMemo(() => data && numberfy(data).map(((d, i) => ({ city: cities?.[i].name, type: cities?.[i].type, id: cities?.[i].id, year, data: d }))).filter((d) => {
    if (category == '全部市縣') {
      return d
    } else {
      return d.type === category
    }
  }), [data, cities, category, year])

  // console.log(intactData)

  const total = useMemo(() => intactData && intactData?.reduce((res, rev) => {
    if (res.length) {
      rev?.data.forEach(r => {
        const index = findIndex(res, { id: r.id })
        if (index > -1) {
          set(res, index, { ...res[index], value: +res[index].value + +r.value, final: +res[index].final + +r.final })
        } else {
          res.push(r)
        }
      })
    } else {
      res = [...rev?.data]
    }
    return res
  }, []), [intactData])

  return {
    data,
    intactData,
    total,
  }
}

export default useGetData
